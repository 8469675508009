.plugins-container {
    background-color: #F8F8F8;
}

.plugin-container {
    background-color: white;
    padding: 24px;
    margin: 0 12px 24px 12px;
    display: flex;
    flex-direction: column;
}

.plugin-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.plugin-header>span {
    color: #021F3F;
    font-size: 18px;
    font-weight: 500;
    margin-left: 16px;
    font-family: 'figtree';
}

.plugin-desc {
    margin: 16px 0 24px 0;
    text-align: left;
    color: #95989A;
    font-size: 14px;
    min-height: 50px;
    font-family: 'figtree';
}