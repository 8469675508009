.profile-container {
    background-color: white;
    padding: 24px;
    text-align: left;
    width: 60%;
    margin: 0 auto;
    display: flex;
}

.profile-addImage {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #95989A;
    border-radius: 4px;
    width: 196px;
    height: 196px;
    cursor: pointer;
    font-size: 12px;
    color: #95989A;
}

.profile-btn-container {
    width: 60%;
    margin: 0 auto;
}