.subscription-container {
    padding: 48px;
    text-align: left;
}

.subscription-container img {
    cursor: pointer;
}

.subscription-table table,
.subscription-table th,
.subscription-table td {
    border: 0.5px solid #95989A;
    border-collapse: collapse;
}

.property-td {
    padding: 24px;
    text-align: left;
}

.value-td {
    padding: 16px;
}