.paperfly-setup-container {
    background-color: white;
    margin: 0 100px;
}

.paperfly-setup-div {
    padding: 24px;
    text-align: left;
}

.paperfly-setup-bottom {
    margin: 50px 100px;
}