.bg-blue {
    background-color: #021F3F;
    min-height: 100vh;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-div {
    margin: 0 0 75px 0;
}

.back-layer {
    position: relative;
    top: 0;
    left: 0;
}

.back-layer>img {
    width: 518px;
}

.mid-layer {
    position: absolute;
    top: 12px;
    left: -18px;
}

.mid-layer>img {
    width: 554px;
}

.front-layer {
    position: absolute;
    top: 12px;
    left: -18px;
}

.front-layer>img {
    width: 588px;
}
.slider-text{
    max-width: 500px;
}

.slider-heading {
    font-size: 28px;
    color: #FFFFFF;
    font-family: Figtree;
    text-align: center;
    min-height: 70px;
    margin: 0;
    padding: 0;
}

.slider-extra {
    font-size: 14px;
    color: #FFFFFF;
    font-family: Figtree;
    text-align: center;
}
.slider-control{
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.arrow-button{
    background-color: transparent;
    border: none;
}
.mid-img-slider{
    margin: 0 8px;
}
.mid-slider-div > img{
    height: 8px;
}